import CrudService from "@/services/shared/http/CrudService";

export default class FAQsService extends CrudService {
  constructor(params = {}) {
    super("faqs", params);
  }
  index(params = {}) {
    return this.api.GET("faqs/get-faqs", params)
  }
}
