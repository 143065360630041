/** API Prefix */
const apiPrefix = '/api/'
const apiUrl = `${process.env.VUE_APP_API_URL}${apiPrefix}`
/** Configuration object */
const config = {
    // End points.
    apiUrl: apiUrl,
    // This will be prefixed in authorization header with token
    tokenType: 'Bearer',
    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
}

export default config
