<template>
  <div class="">
    <FAQsForm ref="form" action="Update" v-model="formData" @formSubmitted="updateStep" />
  </div>
</template>
  
  <script>
import FAQsForm from "@/views/components/FAQs/FAQsForm";
import FAQsService from "@/services/FAQsService";

export default {
  name: "UpdateFAQs",
  components: {
    FAQsForm
  },
  data() {
    return {
      fAQsService: new FAQsService(),
      type: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateStep(e) {
      this.fAQsService.update(this.formData._id, e).then(res => {
        this.$router.go(-1);
      }).catch(err => {
        console.log(err);
       }).finally(() => {
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getPolicy() {
      this.fAQsService.show(this.$route.params.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getPolicy()
  }
}
</script>
  
  <style scoped>
  </style>
  