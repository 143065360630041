var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"chatForm",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"FAQsInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Question En","label-for":"Question En"}},[_c('validation-provider',{attrs:{"name":"Question En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Question En"},model:{value:(_vm.value.questionEn),callback:function ($$v) {_vm.$set(_vm.value, "questionEn", $$v)},expression:"value.questionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Answer En","label-for":"Answer En"}},[_c('validation-provider',{attrs:{"name":"Answer En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Answer En","rows":"3"},model:{value:(_vm.value.answerEn),callback:function ($$v) {_vm.$set(_vm.value, "answerEn", $$v)},expression:"value.answerEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Question Fr","label-for":"Question Fr"}},[_c('validation-provider',{attrs:{"name":"Question Fr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Question Fr"},model:{value:(_vm.value.questionFr),callback:function ($$v) {_vm.$set(_vm.value, "questionFr", $$v)},expression:"value.questionFr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Answer Fr","label-for":"Answer Fr"}},[_c('validation-provider',{attrs:{"name":"Answer Fr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Answer Fr","rows":"3"},model:{value:(_vm.value.answerFr),callback:function ($$v) {_vm.$set(_vm.value, "answerFr", $$v)},expression:"value.answerFr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }